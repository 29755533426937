import { put, takeLatest, call } from 'redux-saga/effects';
import * as Api from 'api/api';
import {
  getProjectsSuccess,
  getProjectsFailure,
  GET_OUR_PROJECTS,
} from './slices';

export function* getOurProjects({ params }) {
  console.log(params);
  try {
    const result = yield call(Api.get, {
      url: '/our-projects/list',
      params,
    });

    yield put(
      getProjectsSuccess({
        docs: result.data,
        page: 1,
        totalElements: result.data?.length || 0,
        totalPages: 1,
      }),
    );
  } catch (e) {
    yield put(getProjectsFailure());
  }
}

export default function* saga() {
  yield takeLatest(GET_OUR_PROJECTS, getOurProjects);
}
