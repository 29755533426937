import { put, takeLatest, call } from 'redux-saga/effects';
import * as Api from 'api/api';
import {
  getSporesNewsSuccess,
  getSporesNewsFailure,
  GET_SPORES_NEWS,
} from './slices';

export function* getSporesNews({ params }) {
  console.log(params);
  try {
    const result = yield call(Api.get, {
      url: '/spores-news/list',
      params,
    });

    yield put(
      getSporesNewsSuccess({
        docs: result.data,
        page: 1,
        totalElements: result.data?.length || 0,
        totalPages: 1,
      }),
    );
  } catch (e) {
    yield put(getSporesNewsFailure());
  }
}

export default function* saga() {
  yield takeLatest(GET_SPORES_NEWS, getSporesNews);
}
