import React from 'react';
import Filter from 'components/Filter';
import Table from 'components/Table';
import GooglePaging from 'components/GooglePaging';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { handleShowConfirm } from 'layout/CommonLayout/actions';
import classes from './AdminPage.module.scss';

const AdminPage = ({
  filter,
  handleSearch,
  data,
  filterOptions,
  tableOptions,
  handleAddNew,
  handleUpdateData,
  handleReorder,
  isReorder,
  renderTable,
  handleViewDetail,
  exportType,
  handleExport,
}) => {
  const dispatch = useDispatch();
  const onDragEnd = (result) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want reorder?',
        handleOk: () => {
          const newLsData = [...data.docs];
          const oldData = newLsData.splice(result.source.index, 1);
          newLsData.splice(result.destination.index, 0, oldData[0]);
          const numberDocs = data.docs.length - 1;
          let newOrder;
          if (result.destination.index === 0) {
            newOrder = data.docs[0].order + data.docs[0].order / 2;
          } else if (result.destination.index === numberDocs) {
            newOrder =
              data.docs[numberDocs].order - data.docs[numberDocs].order / 2;
          } else {
            newOrder =
              data.docs[result.destination.index - 1].order -
              (data.docs[result.destination.index - 1].order -
                data.docs[result.destination.index].order) /
                2;
          }
          if (handleReorder) {
            handleReorder(newLsData[result.destination.index], newOrder);
          }

          console.log(newOrder);
          if (handleUpdateData) {
            handleUpdateData({
              ...data,
              docs: newLsData.map((item, i) => ({
                ...item,
                order: i === result.destination.index ? newOrder : item.order,
              })),
            });
          }
        },
      }),
    );
  };

  return (
    <div className='group'>
      <div className='groupBody'>
        <div className={classes.filter}>
          <Filter
            filter={filter}
            handleSearch={handleSearch}
            options={filterOptions}
          />
        </div>

        <div className={classes.head}>
          <p className={classes.title}>Total: {data?.totalElements}</p>

          {handleAddNew && (
            <a className='btn btnMain btnSmall' onClick={handleAddNew}>
              Add new
            </a>
          )}
        </div>
        {renderTable || (
          <div className={classes.table}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Table
                data={data?.docs}
                tableOptions={tableOptions}
                filter={filter}
                handleSearch={handleSearch}
                isReorder={isReorder}
                handleViewDetail={handleViewDetail}
              />
            </DragDropContext>
          </div>
        )}

        <div className={classes.pagings}>
          <GooglePaging
            pageInfo={{
              page: data?.page,
              totalPages: data?.totalPages,
              totalElements: data?.totalElements,
            }}
            changePage={({ page }) => handleSearch({ page })}
            exportType={exportType}
            handleExport={handleExport}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
