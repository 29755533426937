import moment from 'moment';

export const convertObjectToSearchParams = (values) => {
  if (values) {
    // console.log(values)
    const search = Object.entries(values)
      .filter((entry) => entry[1])
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return `?${search}`;
  }
  return '';
};

export const convertSearchParamsToObject = (search) => {
  if (search) {
    const value = search.slice(1);
    const obj = {};
    value.split('&').forEach((item) => {
      const [key, val] = item.split('=');
      obj[key] = val;
    });
    return obj;
  }
  return {};
};

export const isLoggedIn = () =>
  !!localStorage.getItem('accessToken') ||
  !!sessionStorage.getItem('accessToken');

export function formatStringToNumber(value, isComma = true) {
  if (!value && value !== 0) {
    return '-';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(value).replace(/,/g, isComma ? ',' : '.');
}

export function roundNumber(value, maximumFractionDigits) {
  if (!value && value !== 0) {
    return '-';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits || 2,
  });

  return formatter.format(value).replace(/,/g, ',');
}

export function hideEmail(email) {
  if (!email) return;

  const [first, second] = email.split('@');
  const [...dot] = second.split('.').slice(1);
  return `${first.slice(0, 3)}***@***.${dot && dot.join('.')}`;
}

export const getFileName = (url) => url?.split('/').pop();

export const deleteAccents = (inputStr) => {
  let str = inputStr;
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
};

export const displayAddress = (address) =>
  address &&
  `${address.slice(0, 4)}...${address.slice(
    address.length - 4,
    address.length,
  )}`;

export const getTimeFromType = (type) => {
  if (type === 'LAST_24_HOURS') {
    return {
      endTime: moment().format('YYYY-MM-DD HH:mm'),
      startTime: moment().add(-1, 'days').format('YYYY-MM-DD 00:00'),
    };
  }
  if (type === 'LAST_7_DAYS') {
    return {
      endTime: moment().format('YYYY-MM-DD HH:mm'),
      startTime: moment().add(-7, 'days').format('YYYY-MM-DD 00:00'),
    };
  }
  if (type === 'LAST_30_DAYS') {
    return {
      endTime: moment().format('YYYY-MM-DD HH:mm'),
      startTime: moment().add(-30, 'days').format('YYYY-MM-DD 00:00'),
    };
  }
  if (type === 'LAST_YEAR') {
    return {
      endTime: moment().format('YYYY-MM-DD HH:mm'),
      startTime: moment().add(-1, 'years').format('YYYY-MM-DD HH:mm'),
    };
  }
  return {};
};

const VIDEO_TYPE = ['MP4', 'AVI', 'WEBM'];
export const isVideo = (filename) => {
  const extend = filename ? filename.split('.').pop() : 'File';
  if (VIDEO_TYPE.indexOf(extend.toUpperCase()) !== -1) {
    return true;
  }
  return false;
};
