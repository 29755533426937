export const GET_OUR_PROJECTS = 'admin/ourProjects/getProjects';
export const GET_OUR_PROJECTS_SUCCESS = 'admin/ourProjects/getProjectsSuccess';
export const GET_OUR_PROJECTS_FAILURE = 'admin/ourProjects/getProjectsFailure';

export const getProjects = (params) => ({
  type: GET_OUR_PROJECTS,
  params,
});

export const getProjectsSuccess = (data) => ({
  type: GET_OUR_PROJECTS_SUCCESS,
  data,
});

export const getProjectsFailure = () => ({
  type: GET_OUR_PROJECTS_FAILURE,
});

export const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OUR_PROJECTS:
      return {
        ...state,
        loading: true,
      };
    case GET_OUR_PROJECTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_OUR_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
