import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import defaultAvatarIcon from 'images/logo-mark.svg';
import classNames from 'classnames';
import logoIcon from 'images/logo-main.svg';
import arrowDown from 'images/arrow-down.svg';
import history from 'utils/history';
// import { createStructuredSelector } from 'reselect';
// import { makeSelectProfile } from 'layout/AdminLayout/selectors';
// import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classes from './Header.module.scss';
import MenuButton from './MenuButton';

// const mapStateToProps = createStructuredSelector({
//   profile: makeSelectProfile(),
// });

const Header = ({
  collapse,
  showMenuClass,
  handleShowMenuMobile,
  handleHideMenu,
  pathname,
}) => {
  // const { profile } = useSelector(mapStateToProps);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/auth/login');
  };

  const toggleMenu = () => {
    if (showMenuClass) {
      handleHideMenu();
    } else {
      handleShowMenuMobile();
    }
  };

  return (
    <div className={classes.container}>
      <NavLink to='/admin/campaign'>
        <div
          className={classNames(classes.left, collapse && classes.leftCollapse)}
        >
          <img className={classes.logo} src={logoIcon} alt='logo' />
        </div>
      </NavLink>
      <div className={classes.right}>
        <NavLink
          to='/admin/staking'
          className={classNames(classes.link)}
          activeClassName={classes.activeMenu}
        >
          <span>Staking</span>
        </NavLink>
        <NavLink
          to='/admin/support'
          className={classNames(classes.link)}
          activeClassName={classes.activeMenu}
        >
          <span>Request support</span>
        </NavLink>
        <div className={classes.menus}>
          <div
            className={classNames(
              classes.menu,
              pathname.indexOf('/spores-app/') !== -1 && classes.activeMenu,
            )}
          >
            <span>Spores.app</span>
            <img
              src={arrowDown}
              className={classes.arrowDown}
              alt='arrow-down'
            />
          </div>
          <div className={classes.subMenus}>
            <NavLink
              to='/admin/spores-app/banners'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Banners</span>
            </NavLink>
            <NavLink
              to='/admin/spores-app/our-projects'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Our projects</span>
            </NavLink>
            <NavLink
              to='/admin/spores-app/coming-soon'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Coming soon</span>
            </NavLink>
            <NavLink
              to='/admin/spores-app/spores-news'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Spores news</span>
            </NavLink>
            <NavLink
              to='/admin/spores-app/pools'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Pools</span>
            </NavLink>
          </div>
        </div>
        <div className={classes.menus}>
          <div
            className={classNames(
              classes.menu,
              pathname.indexOf('/republique/') !== -1 && classes.activeMenu,
            )}
          >
            <span>republique</span>
            <img
              src={arrowDown}
              className={classes.arrowDown}
              alt='arrow-down'
            />
          </div>
          <div className={classes.subMenus}>
            <NavLink
              to='/admin/republique/featured-artists'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Featured artists</span>
            </NavLink>

            <NavLink
              to='/admin/republique/partners'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Permanent Collection</span>
            </NavLink>
            <NavLink
              to='/admin/republique/request-gallery'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Request Gallery</span>
            </NavLink>
          </div>
        </div>

        <div className={classes.menus}>
          <div
            className={classNames(
              classes.menu,
              pathname.indexOf('/settings/') !== -1 && classes.activeMenu,
            )}
          >
            <span>Settings</span>
            <img
              src={arrowDown}
              className={classes.arrowDown}
              alt='arrow-down'
            />
          </div>
          <div className={classes.subMenus}>
            <NavLink
              to='/admin/settings/params'
              className={classNames(classes.link)}
              activeClassName={classes.active}
            >
              <span>Manage params</span>
            </NavLink>
          </div>
        </div>

        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            id='dropdown-basic'
            as={React.forwardRef(({ onClick }, ref) => (
              <div
                className={classes.userName}
                ref={ref}
                onClick={(e) => {
                  e.preventDefault();
                  onClick(e);
                }}
              >
                <img
                  className={classes.avatar}
                  src={defaultAvatarIcon}
                  alt='avatar'
                />
                <div className={classes.info}>
                  <p className={classes.name}>Admin Spores</p>
                  <p className={classes.accountType}>Admin</p>
                </div>
              </div>
            ))}
          />

          <Dropdown.Menu>
            <Dropdown.Item>
              <span
                className={classes.dropdownItem}
                onClick={() => {
                  history.push('/admin/profile');
                }}
              >
                Profile
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span
                className={classes.dropdownItem}
                onClick={() => {
                  history.push('/admin/change-password');
                }}
              >
                Change password
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span className={classes.dropdownItem} onClick={handleLogout}>
                <FormattedMessage id='Header.logout' defaultMessage='Logout' />
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={classes.headerMenu}>
        <MenuButton handleToggleMenu={toggleMenu} active={showMenuClass} />

        <img src={logoIcon} className={classes.logoIcon} alt='logo' />

        <a className={classes.logout} onClick={handleLogout}>
          Logout
        </a>
      </div>
    </div>
  );
};

export default Header;
