import { put, takeLatest, call } from 'redux-saga/effects';
import * as Api from 'api/api';
import {
  getRequestGallerySuccess,
  getRequestGalleryFailure,
  GET_REQUEST_GALLERY,
} from './slices';

export function* getRequestGallery({ params }) {
  console.log(params);
  try {
    const result = yield call(Api.get, {
      url: '/request-gallery/request',
      params,
    });

    yield put(
      getRequestGallerySuccess({
        docs: result.data,
        page: 1,
        totalElements: result.data?.length || 0,
        totalPages: 1,
      }),
    );
  } catch (e) {
    yield put(getRequestGalleryFailure());
  }
}

export default function* saga() {
  yield takeLatest(GET_REQUEST_GALLERY, getRequestGallery);
}
