import {
  SHOW_CONFIRM,
  HIDE_CONFIRM,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from './constants';

export const initialState = {
  notification: null,
  showConfirm: false,
  confirm: {},
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };
    case SHOW_CONFIRM:
      return {
        ...state,
        showConfirm: true,
        confirm: action.confirm,
      };
    case HIDE_CONFIRM:
      return {
        ...state,
        showConfirm: false,
        confirm: {},
      };
    default:
      return state;
  }
}
