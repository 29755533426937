import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFilter } from 'hooks/useFilter';
import AdminPage from 'components/AdminPage';
import { convertSearchParamsToObject, deleteAccents } from 'utils/utils';
import moment from 'moment';
import * as Api from 'api/api';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import classes from './RequestGallery.module.scss';
import saga from './saga';
import { makeSelectData } from './selectors';
import reducer, { getRequestGallery } from './slices';

const mapStateToProps = createStructuredSelector({
  requestGalleryData: makeSelectData(),
});

const RequestGallery = ({ location }) => {
  const filterOptions = useMemo(
    () => [
      {
        type: 'SEARCH',
        name: 'textSearch',
        label: 'Search by Name',
      },
    ],
    [],
  );

  /**
   * inject saga và reducer vào hệ thống
   * */
  useInjectSaga({ key: 'requestGallery', saga });
  useInjectReducer({ key: 'requestGallery', reducer });

  /**
   * get data
   */
  const getData = (params) => {
    dispatch(getRequestGallery(params));
  };

  const convertQueryToFilter = (query) => ({
    textSearch: query.textSearch || '',
  });

  const { filter, handleSearch } = useFilter({
    location,
    getData,
    convertQueryToFilter,
  });

  const { requestGalleryData } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const tableOptions = useMemo(
    () => [
      {
        label: 'Updated at',
        name: 'updatedAt',
        renderCol: (item) =>
          moment(item.updatedAt).format('MMM, DD YYYY HH:mm'),
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'code',
        label: 'Code',
      },
      {
        name: 'numberRequest',
        label: 'Number request',
      },
    ],
    [],
  );

  const data = useMemo(() => {
    if (requestGalleryData && requestGalleryData.docs?.length > 0) {
      const params = convertSearchParamsToObject(location.search);

      const newResults = requestGalleryData.docs.filter(
        (item) =>
          !params.textSearch ||
          deleteAccents(`${item.email}`)
            .toLowerCase()
            .indexOf(
              deleteAccents(decodeURI(params.textSearch)).toLowerCase(),
            ) !== -1,
      );
      return {
        ...requestGalleryData,
        docs: newResults,
      };
    }
    return {
      ...requestGalleryData,
      docs: [],
    };
  }, [location.search, requestGalleryData]);

  const handleExport = async () => {
    try {
      const datacsv = await Api.get({
        url: '/request-gallery/request',
        params: {
          isExport: true,
        },
        options: {
          responseType: 'blob',
        },
      });

      const url = window.URL.createObjectURL(new Blob([datacsv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `request-gallery-${moment().format('MM-DD-YYYY')}.csv`,
      ); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.container}>
      <AdminPage
        data={data}
        filter={filter}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        tableOptions={tableOptions}
        handleExport={handleExport}
      />
    </div>
  );
};

export default RequestGallery;
