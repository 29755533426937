import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/Button';
import * as Api from 'api/api';
import closeIcon from 'images/close.svg';
import InputField from 'components/InputField';
import CropImageField from 'components/CropImageField';
import classes from './Form.module.scss';

const Form = ({
  handleSubmit,
  handleClose,
  handleRefreshData,
  selectedRequest,
  reset,
  show,
}) => {
  const [showCropImage, setShowCropImage] = useState(false);

  useEffect(() => {
    if (show) {
      setShowCropImage(true);
    }
    return () => {
      setShowCropImage(false);
    };
  }, [show]);

  useEffect(() => {
    if (show && !selectedRequest?._id) {
      reset();
    }
  }, [show]);
  const [loading, setLoading] = useState(false);

  const handleEditApply = async (values) => {
    try {
      setLoading(true);

      if (selectedRequest?.id) {
        await Api.put({
          url: '/comingsoon/update',
          data: {
            ...values,
            image: values.image?.src,
          },
        });
      } else {
        await Api.post({
          url: '/comingsoon/create',
          data: {
            ...values,
            image: values.image?.src,
          },
        });
      }

      setLoading(false);
      handleRefreshData();
      handleClose();
    } catch (e) {
      console.log('er', e);
      setLoading(false);
    }
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleEditApply)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {selectedRequest?.id ? 'Update coming soon' : 'Create coming soon'}
        </h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        {showCropImage && (
          <Field
            name='image'
            component={CropImageField}
            label='Image'
            width={304}
            height={92}
          />
        )}

        <Field name='title' component={InputField} label='Title' />
      </div>
      <div className={classes.actions}>
        <Button
          className='btn btnMain btnLarge'
          loading={loading}
          type='submit'
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.title || !values.title.trim()) {
    errors.title = 'Please enter title';
  }

  if (!values.image || !values.image?.src) {
    errors.image = 'Please upload image';
  }

  return errors;
};

export default reduxForm({
  form: 'FeaturedArtistsForm',
  validate,
  enableReinitialize: true,
})(Form);
