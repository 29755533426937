export const GET_USER_PROFILE = 'app/AdminLayout/getUserProfile';
export const GET_USER_PROFILE_SUCCESS = 'app/AdminLayout/getUserProfileSuccess';
export const GET_USER_PROFILE_FAILURE = 'app/AdminLayout/getUserProfileFailure';

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
});

export const getUserProfileSuccess = (profile) => ({
  type: GET_USER_PROFILE_SUCCESS,
  profile,
});

export const getUserProfileFailure = () => ({
  type: GET_USER_PROFILE_FAILURE,
});

export const initialState = {
  profile: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile,
      };
    default:
      return state;
  }
}
