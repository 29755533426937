import React, { Component } from 'react';

import Loading from 'react-loading-bar';
import classNames from 'classnames';
import AvatarImageCropper from 'react-avatar-image-cropper';
import closeIcon from 'images/close.svg';
import checkIcon from 'images//check-white.png';
// import { FileURL } from 'utils/config';
import axios from 'axios';
import { renderField } from '../../Form';
import * as Api from '../../api/api';
import classes from './CropImageField.module.scss';

export class CropImageField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleUpload = async (file) => {
    try {
      this.setState({
        loading: true,
      });

      const result = await Api.get({
        url: '/static/public/presignurl',
        hideError: true,
        params: {
          filename: file.name,
        },
      });

      const extend = file.name ? file.name.split('.').pop() : 'File';

      await axios.put(result.data.url, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': extend === 'svg' && 'image/svg+xml',
        },
      });

      const [imageUrl] = result.data.url.split('?');

      const { input } = this.props;
      input.onChange({ file, src: imageUrl, loading: false });
      this.setState({
        loading: false,
      });
    } catch (e) {
      // const { input } = this.props;
      // input.onChange(null);
      this.setState({
        loading: false,
      });
    }
  };

  apply = async (file) => {
    const { input } = this.props;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      input.onChange({ file, presrc: reader.result, loading: true });
      this.handleUpload(file);
    };
  };

  render() {
    const { width, height, input } = this.props;
    const { loading } = this.state;
    const actions = [
      <button key={0} type='button' className={classes.btnRemove}>
        {' '}
        <img src={closeIcon} alt='close' className={classes.closeIcon} />{' '}
      </button>,
      <button key={1} type='button' className={classes.btnAccept}>
        {' '}
        <img src={checkIcon} alt='close' className={classes.checkIcon} />{' '}
      </button>,
    ];
    const maxsize = 1024 * 1024 * 3;
    return (
      <div className={classes.container}>
        <div
          style={{
            width,
            height,
            backgroundImage: `url('${
              input.value?.src || input.value?.presrc
            }')`,
          }}
          className={classNames(classes.wrapper, 'cropImage')}
        >
          <AvatarImageCropper
            apply={this.apply}
            isBack
            actions={actions}
            maxsize={maxsize}
            className={classes.avatarWrapper}
            text='Upload image'
          />
          <Loading show={loading} color='#005581' />
        </div>
      </div>
    );
  }
}

export default renderField(CropImageField);
