import React from 'react';

const Status = ({ status }) => {
  let text;
  let color;
  switch (status) {
    case 'Open':
      text = 'Open';
      color = '#89C87B';
      break;
    case 'Filled':
      text = 'Filled';
      color = '#E84B35';
      break;
    default:
      text = '';
      break;
  }

  return <div style={{ color }}>{text}</div>;
};

export default Status;
