import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';
import { useFilter } from 'hooks/useFilter';
import AdminPage from 'components/AdminPage';
import { convertSearchParamsToObject, deleteAccents } from 'utils/utils';
import moment from 'moment';
import * as Api from 'api/api';
import { handleShowConfirm } from 'layout/CommonLayout/actions';
import { POOL_STATUS_OBJ, POOL_STATUS } from 'utils/constants';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import classes from './ManagePool.module.scss';
import saga from './saga';
import { makeSelectData } from './selectors';
import reducer, { getData as getPoolData, getDataSuccess } from './slices';
import Form from './components/Form';

const mapStateToProps = createStructuredSelector({
  poolData: makeSelectData(),
});

const ManagePool = ({ location }) => {
  const filterOptions = useMemo(
    () => [
      {
        type: 'SEARCH',
        name: 'textSearch',
        label: 'Search by Name',
      },
      {
        type: 'SELECT',
        name: 'poolSatus',
        label: 'Pool status',
        options: POOL_STATUS,
      },
    ],
    [],
  );

  /**
   * inject saga và reducer vào hệ thống
   * */
  useInjectSaga({ key: 'pool', saga });
  useInjectReducer({ key: 'pool', reducer });

  /**
   * state
   */
  const [showForm, setShowForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();

  /**
   * get data
   */
  const getData = (params) => {
    dispatch(getPoolData(params));
  };

  const convertQueryToFilter = (query) => ({
    textSearch: query.textSearch || '',
  });

  const { filter, handleSearch } = useFilter({
    location,
    getData,
    convertQueryToFilter,
  });

  const { poolData } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const handleRefreshData = () => {
    const params = convertSearchParamsToObject(location.search);
    dispatch(getPoolData(params));
  };

  const handleRemove = (item) => {
    console.log(item);
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want delete this pool',
        handleOk: async () => {
          await Api.deleteData({
            url: '/pool/delete',
            params: {
              id: item.id,
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const handleChangeStatus = (active, item) => {
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want change status this pool',
        handleOk: async () => {
          await Api.put({
            url: '/pool/update',
            data: {
              ...item,
              status: active ? 'ACTIVE' : 'INACTIVE',
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const tableOptions = useMemo(
    () => [
      {
        label: 'Created at',
        name: 'createdAt',
        renderCol: (item) =>
          moment(item.createdAt).format('MMM, DD YYYY HH:mm'),
      },
      {
        name: 'pool',
        label: 'Pool',
        renderCol: (item) => (
          <div className={classes.row}>
            <img src={item.logo} className={classes.image} alt='img' />
            <div>
              <p className={classes.name}>{item.name}</p>
              <p className={classes.symbol}>{item.symbol}</p>
            </div>
          </div>
        ),
      },
      {
        name: 'totalRaise',
        label: 'Total raise',
      },
      {
        name: 'date',
        label: 'Date',
        renderCol: (item) => (
          <div>
            {item.startDate} <br />
            {item.endDate}
          </div>
        ),
      },
      {
        name: 'price',
        label: 'Price',
      },
      {
        name: 'progress',
        label: 'Progress',
      },
      {
        name: 'currentValue',
        label: 'Current Value',
      },
      {
        name: 'poolStatus',
        label: 'Pool status',
        renderCol: (item) => POOL_STATUS_OBJ[item.poolStatus],
      },
      {
        name: 'multipleTimes',
        label: 'Multiple times',
      },
      {
        name: 'action',
        type: 'ACTION',
        direction: 'row',
        handleEdit: (item) => {
          setSelectedRequest(item);
          setShowForm(true);
        },
        handleRemove,
        handleChangeStatus,
        status: 'ACTIVE',
      },
    ],
    [],
  );

  const handleReorder = async (item, order) => {
    try {
      await Api.put({
        url: `/pool/update`,
        data: {
          ...item,
          order,
        },
      });

      // handleRefreshData();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleUpdateData = (data) => {
    dispatch(getDataSuccess(data));
  };

  const data = useMemo(() => {
    if (poolData && poolData.docs?.length > 0) {
      const params = convertSearchParamsToObject(location.search);

      const newResults = poolData.docs.filter(
        (item) =>
          (!params.textSearch ||
            deleteAccents(`${item.description} ${item.title}`)
              .toLowerCase()
              .indexOf(
                deleteAccents(decodeURI(params.textSearch)).toLowerCase(),
              ) !== -1) &&
          (!params.poolStatus || params.poolStatus === item.poolStatus),
      );
      return {
        ...poolData,
        docs: newResults,
      };
    }
    return {
      ...poolData,
      docs: [],
    };
  }, [location.search, poolData]);

  return (
    <div className={classes.container}>
      <AdminPage
        data={data}
        filter={filter}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        tableOptions={tableOptions}
        handleAddNew={() => {
          setSelectedRequest(null);
          setShowForm(true);
        }}
        isReorder
        handleReorder={handleReorder}
        handleUpdateData={handleUpdateData}
      />

      <Modal show={showForm} onHide={() => setShowForm(false)} size='md'>
        <Form
          initialValues={
            selectedRequest
              ? {
                  ...selectedRequest,
                  logo: selectedRequest?.logo && {
                    src: selectedRequest?.logo || '',
                  },
                  poolStatus: {
                    value: selectedRequest.poolStatus,
                    label: POOL_STATUS_OBJ[selectedRequest.poolStatus],
                  },
                }
              : {}
          }
          handleClose={() => setShowForm(false)}
          handleRefreshData={handleRefreshData}
          selectedRequest={selectedRequest}
          show={showForm}
        />
      </Modal>
    </div>
  );
};

export default ManagePool;
