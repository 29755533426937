import React from 'react';
import cancelIcon from 'images/cancel.svg';
import editIcon from 'images/edit.svg';
import classNames from 'classnames';
import SwitchField from 'components/SwitchField';
import classes from './TableAction.module.scss';

const TableAction = ({
  handleRemove,
  handleEdit,
  handleCopy,
  handleChangeStatus,
  status,
  direction,
  handleViewDetail,
  size,
  item,
  More,
}) => (
  <div
    className={classNames(
      'tbAction',
      direction === 'col' && classes.col,
      size === 'lg' && classes.containerLg,
    )}
  >
    {handleCopy && (
      <a className={classes.btnCopy} onClick={handleCopy}>
        Copy
      </a>
    )}
    {handleViewDetail && (
      <a className={classes.btnCopy} onClick={handleViewDetail}>
        View detail
      </a>
    )}
    {handleRemove && (
      <a className='btnRemove' onClick={() => handleRemove(item)}>
        <img src={cancelIcon} className='removeIcon' alt='remove' />
      </a>
    )}
    {handleEdit && (
      <a className='btnEdit' onClick={() => handleEdit(item)}>
        <img src={editIcon} alt='edit' />
      </a>
    )}

    {handleChangeStatus && (
      <div className={classes.ml10}>
        <SwitchField
          input={{
            value: status,
            onChange: (active) => handleChangeStatus(active, item),
          }}
        />
      </div>
    )}

    {More && More(item)}
  </div>
);

export default TableAction;
