import { put, takeLatest, call } from 'redux-saga/effects';
import * as Api from 'api/api';
import { getStakingSuccess, getStakingFailure, GET_STAKING } from './slices';

export function* getStaking({ params }) {
  console.log(params);
  try {
    const result = yield call(Api.get, {
      url: '/staking/list',
      params,
    });

    yield put(
      getStakingSuccess({
        docs: result.data,
        page: 1,
        totalElements: result.data?.length || 0,
        totalPages: 1,
      }),
    );
  } catch (e) {
    yield put(getStakingFailure());
  }
}

export default function* saga() {
  yield takeLatest(GET_STAKING, getStaking);
}
