import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';
import { useFilter } from 'hooks/useFilter';
import AdminPage from 'components/AdminPage';
import { convertSearchParamsToObject, deleteAccents } from 'utils/utils';
import moment from 'moment';
import * as Api from 'api/api';
import { handleShowConfirm } from 'layout/CommonLayout/actions';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import classes from './Staking.module.scss';
import saga from './saga';
import { makeSelectData } from './selectors';
import reducer, { getStaking, getStakingSuccess } from './slices';
import Form from './components/Form';
import Status from '../Users/components/Status';

const mapStateToProps = createStructuredSelector({
  stakingData: makeSelectData(),
});

const Staking = ({ location }) => {
  const filterOptions = useMemo(
    () => [
      {
        type: 'SEARCH',
        name: 'textSearch',
        label: 'Search by Name',
      },
    ],
    [],
  );

  /**
   * inject saga và reducer vào hệ thống
   * */
  useInjectSaga({ key: 'staking', saga });
  useInjectReducer({ key: 'staking', reducer });

  /**
   * state
   */
  const [showForm, setShowForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();

  /**
   * get data
   */
  const getData = (params) => {
    dispatch(getStaking(params));
  };

  const convertQueryToFilter = (query) => ({
    textSearch: query.textSearch || '',
  });

  const { filter, handleSearch } = useFilter({
    location,
    getData,
    convertQueryToFilter,
  });

  const { stakingData } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const handleRefreshData = () => {
    const params = convertSearchParamsToObject(location.search);
    dispatch(getStaking(params));
  };

  const handleRemove = (item) => {
    console.log(item);
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want delete this pool',
        handleOk: async () => {
          await Api.deleteData({
            url: '/staking/delete',
            params: {
              id: item.id,
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const handleChangeStatus = (active, item) => {
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want change status this pool',
        handleOk: async () => {
          await Api.put({
            url: '/staking/update',
            data: {
              ...item,
              status: active ? 'ACTIVE' : 'INACTIVE',
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const tableOptions = useMemo(
    () => [
      {
        name: 'title',
        label: 'Title',
      },
      {
        name: 'description',
        label: 'Description',
      },
      {
        name: 'poolSize',
        label: 'Pool size',
      },
      {
        name: 'startDate',
        label: 'Start date',
        renderCol: (item) => moment(item.startDate).format('MM/DD/YYYY'),
      },
      {
        name: 'startTime',
        label: 'Start time',
      },
      {
        label: 'Days\nOpen\nFor Stake',
        name: 'daysOpen',
        className: 'prewrap',
      },
      {
        label: 'Days Of\nMandatory\nLock',
        name: 'daysLock',
        className: 'prewrap',
      },
      {
        label: 'Early Reward\nReturn\n(APR %)',
        name: 'rewardReturn',
        className: 'prewrap',
      },
      {
        label: 'Days\nUntil\nMaturity',
        name: 'daysMaturity',
        className: 'prewrap',
      },
      {
        label: 'Maturity\nReturn\n(APR %)',
        name: 'maturityReturn',
        className: 'prewrap',
      },
      {
        name: 'stakingStatus',
        label: 'Pool status',
        renderCol: (item) => <Status status={item.stakingStatus} />,
      },
      {
        name: 'action',
        type: 'ACTION',
        direction: 'row',
        handleEdit: (item) => {
          setSelectedRequest(item);
          setShowForm(true);
        },
        handleRemove,
        handleChangeStatus,
        status: 'ACTIVE',
      },
    ],
    [],
  );

  const handleReorder = async (item, order) => {
    try {
      await Api.put({
        url: `/staking/update`,
        data: {
          ...item,
          order,
        },
      });

      // handleRefreshData();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleUpdateData = (data) => {
    dispatch(getStakingSuccess(data));
  };

  const data = useMemo(() => {
    if (stakingData && stakingData.docs?.length > 0) {
      const params = convertSearchParamsToObject(location.search);

      const newResults = stakingData.docs.filter(
        (item) =>
          !params.textSearch ||
          deleteAccents(`${item.description} ${item.title}`)
            .toLowerCase()
            .indexOf(
              deleteAccents(decodeURI(params.textSearch)).toLowerCase(),
            ) !== -1,
      );
      return {
        ...stakingData,
        docs: newResults,
      };
    }
    return {
      ...stakingData,
      docs: [],
    };
  }, [location.search, stakingData]);

  return (
    <div className={classes.container}>
      <AdminPage
        data={data}
        filter={filter}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        tableOptions={tableOptions}
        handleAddNew={() => {
          setSelectedRequest(null);
          setShowForm(true);
        }}
        isReorder
        handleReorder={handleReorder}
        handleUpdateData={handleUpdateData}
      />

      <Modal show={showForm} onHide={() => setShowForm(false)} size='md'>
        <Form
          initialValues={{
            ...selectedRequest,
            stakingStatus: selectedRequest?.stakingStatus && {
              label: selectedRequest.stakingStatus,
              value: selectedRequest.stakingStatus,
            },
            startDate:
              selectedRequest?.startDate &&
              moment(selectedRequest?.startDate).format('MM/DD/YYYY'),
          }}
          handleClose={() => setShowForm(false)}
          handleRefreshData={handleRefreshData}
          selectedRequest={selectedRequest}
          show={showForm}
        />
      </Modal>
    </div>
  );
};

export default Staking;
