import { call, put, takeLatest } from 'redux-saga/effects';
import * as Api from 'api/api';
import {
  GET_USER_PROFILE,
  getUserProfileSuccess,
  getUserProfileFailure,
} from './slices';

function* getUserProfile() {
  try {
    const result = yield call(Api.get, {
      url: '/user/profile',
    });
    yield put(getUserProfileSuccess(result.data));
  } catch (e) {
    yield put(getUserProfileFailure());
  }
}

export default function* saga() {
  yield takeLatest(GET_USER_PROFILE, getUserProfile);
}
