import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputField from 'components/InputField';
import Button from 'components/Button';
import * as Api from 'api/api';
import CropImageField from 'components/CropImageField';
import classes from './Profile.module.scss';

const Profile = ({ handleSubmit }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmitProfile = async (values) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append('fullname', values.fullname);
      if (values.phone_number) {
        formData.append('phone_number', values.phone_number);
      }
      if (values.avatar?.file) {
        formData.append(
          'avatar',
          values?.avatar?.file,
          values.avatar?.file?.name,
        );
      }

      await Api.put({
        url: '/user/profile',
        data: formData,
      });
      setLoading(false);
    } catch (e) {
      console.log('e', e);
      setLoading(false);
    }
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleSubmitProfile)}
    >
      <h2 className={classes.title}>Edit user</h2>
      <div className={classes.content}>
        <Field
          name='avatar'
          component={CropImageField}
          label='Avatar'
          width={250}
          height={250}
        />

        <Field
          name='phone_number'
          component={InputField}
          label='Phone number'
          inputType='number'
        />
        <Field name='fullname' component={InputField} label='Full name' />
        <Button
          className='btn btnMain btnLarge w100'
          loading={loading}
          type='submit'
        >
          Update
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.fullname || !values.fullname.trim()) {
    errors.fullname = 'Please enter full name';
  }

  return errors;
};

export default reduxForm({
  form: 'Profile',
  validate,
  enableReinitialize: true,
})(Profile);
