import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';
import { useFilter } from 'hooks/useFilter';
import AdminPage from 'components/AdminPage';
import { convertSearchParamsToObject, deleteAccents } from 'utils/utils';
import moment from 'moment';
import * as Api from 'api/api';
import { handleShowConfirm } from 'layout/CommonLayout/actions';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import classes from './ComingSoon.module.scss';
import saga from './saga';
import { makeSelectData } from './selectors';
import reducer, { getComingSoon, getComingSoonSuccess } from './slices';
import Form from './components/Form';
import Status from '../Users/components/Status';

const mapStateToProps = createStructuredSelector({
  comingSoonData: makeSelectData(),
});

const ComingSoon = ({ location }) => {
  const filterOptions = useMemo(
    () => [
      {
        type: 'SEARCH',
        name: 'textSearch',
        label: 'Search by Name',
      },
    ],
    [],
  );

  /**
   * inject saga và reducer vào hệ thống
   * */
  useInjectSaga({ key: 'comingSoon', saga });
  useInjectReducer({ key: 'comingSoon', reducer });

  /**
   * state
   */
  const [showForm, setShowForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();

  /**
   * get data
   */
  const getData = (params) => {
    dispatch(getComingSoon(params));
  };

  const convertQueryToFilter = (query) => ({
    textSearch: query.textSearch || '',
  });

  const { filter, handleSearch } = useFilter({
    location,
    getData,
    convertQueryToFilter,
  });

  const { comingSoonData } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const handleRefreshData = () => {
    const params = convertSearchParamsToObject(location.search);
    dispatch(getComingSoon(params));
  };

  const handleRemove = (item) => {
    console.log(item);
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want delete this coming soon',
        handleOk: async () => {
          await Api.deleteData({
            url: '/comingsoon/delete',
            params: {
              id: item.id,
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const handleChangeStatus = (active, item) => {
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want change status this coming soon',
        handleOk: async () => {
          await Api.put({
            url: '/comingsoon/update',
            data: {
              ...item,
              status: active ? 'ACTIVE' : 'INACTIVE',
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const tableOptions = useMemo(
    () => [
      {
        label: 'Created at',
        name: 'createdAt',
        renderCol: (item) =>
          moment(item.createdAt).format('MMM, DD YYYY HH:mm'),
      },
      {
        name: 'image',
        label: 'Image',
        renderCol: (item) => (
          <img src={item.image} className={classes.image} alt='img' />
        ),
      },
      {
        name: 'title',
        label: 'Title',
      },
      {
        name: 'status',
        label: 'Status',
        renderCol: (item) => <Status status={item.status} />,
      },
      {
        name: 'action',
        type: 'ACTION',
        direction: 'row',
        handleEdit: (item) => {
          setSelectedRequest(item);
          setShowForm(true);
        },
        handleRemove,
        handleChangeStatus,
        status: 'ACTIVE',
      },
    ],
    [],
  );

  const handleReorder = async (item, order) => {
    try {
      await Api.put({
        url: `/comingsoon/update`,
        data: {
          ...item,
          order,
        },
      });

      // handleRefreshData();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleUpdateData = (data) => {
    dispatch(getComingSoonSuccess(data));
  };

  const data = useMemo(() => {
    if (comingSoonData && comingSoonData.docs?.length > 0) {
      const params = convertSearchParamsToObject(location.search);

      const newResults = comingSoonData.docs.filter(
        (item) =>
          !params.textSearch ||
          deleteAccents(`${item.description} ${item.title}`)
            .toLowerCase()
            .indexOf(
              deleteAccents(decodeURI(params.textSearch)).toLowerCase(),
            ) !== -1,
      );
      return {
        ...comingSoonData,
        docs: newResults,
      };
    }
    return {
      ...comingSoonData,
      docs: [],
    };
  }, [location.search, comingSoonData]);

  return (
    <div className={classes.container}>
      <AdminPage
        data={data}
        filter={filter}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        tableOptions={tableOptions}
        handleAddNew={() => {
          setSelectedRequest(null);
          setShowForm(true);
        }}
        isReorder
        handleReorder={handleReorder}
        handleUpdateData={handleUpdateData}
      />

      <Modal show={showForm} onHide={() => setShowForm(false)} size='md'>
        <Form
          initialValues={{
            image: selectedRequest?.image && {
              src: selectedRequest?.image || '',
            },
            title: selectedRequest?.title || '',
            id: selectedRequest?.id,
            status: selectedRequest?.status,
          }}
          handleClose={() => setShowForm(false)}
          handleRefreshData={handleRefreshData}
          selectedRequest={selectedRequest}
          show={showForm}
        />
      </Modal>
    </div>
  );
};

export default ComingSoon;
