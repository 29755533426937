export const GET_DATA = 'admin/Pool/getData';
export const GET_DATA_SUCCESS = 'admin/Pool/getDataSuccess';
export const GET_DATA_FAILURE = 'admin/Pool/getDataFailure';

export const getData = (params) => ({
  type: GET_DATA,
  params,
});

export const getDataSuccess = (data) => ({
  type: GET_DATA_SUCCESS,
  data,
});

export const getDataFailure = () => ({
  type: GET_DATA_FAILURE,
});

export const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
