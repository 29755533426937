import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/Button';
import * as Api from 'api/api';
import closeIcon from 'images/close.svg';
import TextAreaField from 'components/TextAreaField';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import { SPORES_NEWS_TYPES } from 'utils/constants';
import CropImageField from 'components/CropImageField';
import Loader from 'react-loader-spinner';
import { validURL } from 'utils/validators';
import classes from './Form.module.scss';

let timeout = null;

const Form = ({
  handleSubmit,
  handleClose,
  handleRefreshData,
  selectedRequest,
  reset,
  show,
  change,
}) => {
  const [showCropImage, setShowCropImage] = useState(false);
  const [loadingGetMeta, setLoadingGetMeta] = useState(false);

  useEffect(() => {
    if (show) {
      setShowCropImage(true);
    }
    return () => {
      setShowCropImage(false);
    };
  }, [show]);

  useEffect(() => {
    if (show && !selectedRequest?._id) {
      reset();
    }
  }, [show]);
  const [loading, setLoading] = useState(false);

  const handleEditApply = async (values) => {
    try {
      setLoading(true);

      if (selectedRequest?.id) {
        await Api.put({
          url: '/spores-news/update',
          data: {
            ...values,
            image: values.image?.src,
            type: values?.type?.value,
          },
        });
      } else {
        await Api.post({
          url: '/spores-news/create',
          data: {
            ...values,
            image: values.image?.src,
            type: values?.type?.value,
          },
        });
      }

      setLoading(false);
      handleRefreshData();
      handleClose();
    } catch (e) {
      console.log('er', e);
      setLoading(false);
    }
  };

  const handleChangeLink = (val) => {
    if (!val || !validURL(val)) {
      return;
    }
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(async () => {
      try {
        setLoadingGetMeta(true);
        const preview = await Api.get({
          url: '/spores-news/see-link',
          params: {
            url: val,
          },
        });

        if (preview.data) {
          change(
            'image',
            preview?.data?.imageUrl && { src: preview?.data?.imageUrl },
          );
          change('title', preview?.data?.title || '');
          change('description', preview?.data?.description || '');
        }

        setLoadingGetMeta(false);
      } catch (e) {
        setLoadingGetMeta(false);
      }
    }, 500);
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleEditApply)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {selectedRequest?.id ? 'Update spores news' : 'Create spores new'}
        </h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        <Field
          name='type'
          component={SelectField}
          label='Spores news type'
          options={SPORES_NEWS_TYPES}
        />
        <div className={classes.linkWrapper}>
          <Field
            name='link'
            component={InputField}
            label='Link'
            placeholder='Please enter link'
            onChange={handleChangeLink}
            customClass={classes.linkField}
          />
          {loadingGetMeta && (
            <div className={classes.loader}>
              <Loader type='Oval' color='#333333' height={20} width={20} />
            </div>
          )}
        </div>

        {showCropImage && (
          <Field
            name='image'
            component={CropImageField}
            label='Image'
            width={400}
            height={(400 * 506) / 900}
          />
        )}

        <Field name='title' component={InputField} label='Title' />
        <Field
          name='description'
          component={TextAreaField}
          label='Description'
          maxLength={1000}
        />
      </div>
      <div className={classes.actions}>
        <Button
          className='btn btnMain btnLarge'
          loading={loading}
          type='submit'
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.title || !values.title.trim()) {
    errors.title = 'Please enter title';
  }

  if (!values.description || !values.description.trim()) {
    errors.description = 'Please enter description';
  }

  if (!values.link || !values.link.trim()) {
    errors.link = 'Please enter link';
  }

  if (!values.type) {
    errors.type = 'Please select type';
  }

  if (!values.image || !values.image?.src) {
    errors.image = 'Please upload image';
  }

  return errors;
};

export default reduxForm({
  form: 'FeaturedArtistsForm',
  validate,
  enableReinitialize: true,
})(Form);
