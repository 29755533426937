export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const STATUS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

export const STATUS_OBJ = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const POOL_STATUS = [
  {
    label: 'Upcoming',
    value: 'UPCOMING',
  },
  {
    label: 'Live',
    value: 'LIVE',
  },
  {
    label: 'Ended',
    value: 'ENDED',
  },
];

export const POOL_STATUS_OBJ = {
  UPCOMING: 'Upcoming',
  LIVE: 'Live',
  ENDED: 'Ended',
};

export const NUMBER_PER_PAGES = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 50,
    label: 50,
  },
];

export const REQUEST_SUPPORT_STATUS = [
  {
    label: 'Waiting',
    value: 'WAITING',
  },
  {
    label: 'Processing',
    value: 'PROCESSING',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

export const REQUEST_SUPPORT_STATUS_OBJ = {
  WAITING: 'Waiting',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  REJECTED: 'Rejected',
};

export const SOURCES = [
  {
    label: 'Republique',
    value: 'REPUBLIQUE',
  },
  {
    label: 'Spores app',
    value: 'SPORES_APP',
  },
];

export const SOURCES_OBJ = {
  REPUBLIQUE: 'Republique',
  SPORES_APP: 'Spores app',
};

export const TYPES = [
  {
    label: 'Customer support',
    value: 'CUSTOMER_SUPPORT',
  },
  {
    label: 'Partnership',
    value: 'PARTNERSHIP',
  },
];

export const TYPES_OBJ = {
  CUSTOMER_SUPPORT: 'Customer support',
  PARTNERSHIP: 'Partnership',
};

export const SPORES_NEWS_TYPES = [
  {
    label: 'Press releases',
    value: 'PRESS_RELEASES',
  },
  {
    label: 'Socials',
    value: 'SOCIALS',
  },
];

export const SPORES_NEWS_TYPES_OBJ = {
  PRESS_RELEASES: 'Press releases',
  SOCIALS: 'Socials',
};
