import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from 'utils/utils';

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isLoggedIn()) {
        return <Redirect to='/admin/user' />;
      }
      return <Component {...rest} {...props} />;
    }}
  />
);

export default AuthRoute;
