export const GET_COMING_SOON = 'admin/comingSoon/getComingSoon';
export const GET_COMING_SOON_SUCCESS = 'admin/comingSoon/getComingSoonSuccess';
export const GET_COMING_SOON_FAILURE = 'admin/comingSoon/getComingSoonFailure';

export const getComingSoon = (params) => ({
  type: GET_COMING_SOON,
  params,
});

export const getComingSoonSuccess = (data) => ({
  type: GET_COMING_SOON_SUCCESS,
  data,
});

export const getComingSoonFailure = () => ({
  type: GET_COMING_SOON_FAILURE,
});

export const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMING_SOON:
      return {
        ...state,
        loading: true,
      };
    case GET_COMING_SOON_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_COMING_SOON_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
