import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';
import { useFilter } from 'hooks/useFilter';
import AdminPage from 'components/AdminPage';
import { convertSearchParamsToObject, deleteAccents } from 'utils/utils';
import moment from 'moment';
import * as Api from 'api/api';
import { handleShowConfirm } from 'layout/CommonLayout/actions';
import { SPORES_NEWS_TYPES_OBJ } from 'utils/constants';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import classes from './SporesNews.module.scss';
import saga from './saga';
import { makeSelectData } from './selectors';
import reducer, { getSporesNews, getSporesNewsSuccess } from './slices';
import Form from './components/Form';
import Status from '../Users/components/Status';

const mapStateToProps = createStructuredSelector({
  sporesNewsData: makeSelectData(),
});

const SporesNews = ({ location }) => {
  const filterOptions = useMemo(
    () => [
      {
        type: 'SEARCH',
        name: 'textSearch',
        label: 'Search by Name',
      },
    ],
    [],
  );

  /**
   * inject saga và reducer vào hệ thống
   * */
  useInjectSaga({ key: 'sporesNews', saga });
  useInjectReducer({ key: 'sporesNews', reducer });

  /**
   * state
   */
  const [showForm, setShowForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();

  /**
   * get data
   */
  const getData = (params) => {
    dispatch(getSporesNews(params));
  };

  const convertQueryToFilter = (query) => ({
    textSearch: query.textSearch || '',
  });

  const { filter, handleSearch } = useFilter({
    location,
    getData,
    convertQueryToFilter,
  });

  const { sporesNewsData } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const handleRefreshData = () => {
    const params = convertSearchParamsToObject(location.search);
    dispatch(getSporesNews(params));
  };

  const handleRemove = (item) => {
    console.log(item);
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want delete this news',
        handleOk: async () => {
          await Api.deleteData({
            url: '/spores-news/delete',
            params: {
              id: item.id,
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const handleChangeStatus = (active, item) => {
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description: 'Do you want change status this news',
        handleOk: async () => {
          await Api.put({
            url: '/spores-news/update',
            data: {
              ...item,
              status: active ? 'ACTIVE' : 'INACTIVE',
            },
          });
          handleRefreshData();
        },
      }),
    );
  };

  const tableOptions = useMemo(
    () => [
      {
        label: 'Created at',
        name: 'createdAt',
        renderCol: (item) =>
          moment(item.createdAt).format('MMM, DD YYYY HH:mm'),
      },
      {
        name: 'image',
        label: 'Image',
        renderCol: (item) => (
          <img src={item.image} className={classes.image} alt='img' />
        ),
      },
      {
        name: 'title',
        label: 'Title',
      },
      {
        name: 'description',
        label: 'Description',
        renderCol: (item) => (
          <div className={classes.description}>{item.description} </div>
        ),
      },
      {
        name: 'link',
        label: 'Link',
        renderCol: (item) => (
          <a
            className={classes.link}
            href={item.link}
            target='_blank'
            rel='noreferrer'
          >
            Link{' '}
          </a>
        ),
      },
      {
        name: 'type',
        label: 'Type',
        renderCol: (item) => (
          <div className={classes.type}>
            {SPORES_NEWS_TYPES_OBJ[item.type]}{' '}
          </div>
        ),
      },
      {
        name: 'status',
        label: 'Status',
        renderCol: (item) => <Status status={item.status} />,
      },
      {
        name: 'action',
        type: 'ACTION',
        direction: 'row',
        handleEdit: (item) => {
          setSelectedRequest(item);
          setShowForm(true);
        },
        handleRemove,
        handleChangeStatus,
        status: 'ACTIVE',
      },
    ],
    [],
  );

  const handleReorder = async (item, order) => {
    try {
      await Api.put({
        url: `/spores-news/update`,
        data: {
          ...item,
          order,
        },
      });

      // handleRefreshData();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleUpdateData = (data) => {
    dispatch(getSporesNewsSuccess(data));
  };

  const data = useMemo(() => {
    if (sporesNewsData && sporesNewsData.docs?.length > 0) {
      const params = convertSearchParamsToObject(location.search);

      const newResults = sporesNewsData.docs.filter(
        (item) =>
          !params.textSearch ||
          deleteAccents(`${item.description} ${item.title}`)
            .toLowerCase()
            .indexOf(
              deleteAccents(decodeURI(params.textSearch)).toLowerCase(),
            ) !== -1,
      );
      return {
        ...sporesNewsData,
        docs: newResults,
      };
    }
    return {
      ...sporesNewsData,
      docs: [],
    };
  }, [location.search, sporesNewsData]);

  return (
    <div className={classes.container}>
      <AdminPage
        data={data}
        filter={filter}
        handleSearch={handleSearch}
        filterOptions={filterOptions}
        tableOptions={tableOptions}
        handleAddNew={() => {
          setSelectedRequest(null);
          setShowForm(true);
        }}
        isReorder
        handleReorder={handleReorder}
        handleUpdateData={handleUpdateData}
      />

      <Modal show={showForm} onHide={() => setShowForm(false)} size='md'>
        <Form
          initialValues={{
            image: selectedRequest?.image && {
              src: selectedRequest?.image || '',
            },
            title: selectedRequest?.title || '',
            description: selectedRequest?.description || '',
            link: selectedRequest?.link || '',
            type: selectedRequest?.type && {
              label: SPORES_NEWS_TYPES_OBJ[selectedRequest?.type],
              value: selectedRequest?.type,
            },
            id: selectedRequest?.id,
            status: selectedRequest?.status,
          }}
          handleClose={() => setShowForm(false)}
          handleRefreshData={handleRefreshData}
          selectedRequest={selectedRequest}
          show={showForm}
        />
      </Modal>
    </div>
  );
};

export default SporesNews;
