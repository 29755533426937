import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectCommon = (state) => state.common || initialState;

export const makeSelectNotification = () =>
  createSelector(selectCommon, (commonState) => commonState.notification);

export const makeSelectShowConfirm = () =>
  createSelector(selectCommon, (commonState) => commonState.showConfirm);

export const makeSelectConfirm = () =>
  createSelector(selectCommon, (commonState) => commonState.confirm);
