import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/Button';
import * as Api from 'api/api';
import closeIcon from 'images/close.svg';
import InputField from 'components/InputField';
// import CropImageField from 'components/CropImageField';
import DatePickerField from 'components/DatePickerField';
import SelectField from 'components/SelectField';
import { POOL_STATUS } from 'utils/constants';
import DropzonUploaderField from 'components/DropzonUploaderField';
import classes from './Form.module.scss';

const Form = ({
  handleSubmit,
  handleClose,
  handleRefreshData,
  selectedRequest,
  reset,
  show,
}) => {
  const [showCropImage, setShowCropImage] = useState(false);

  useEffect(() => {
    if (show) {
      setShowCropImage(true);
    }
    return () => {
      setShowCropImage(false);
    };
  }, [show]);

  useEffect(() => {
    if (show && !selectedRequest?._id) {
      reset();
    }
  }, [show]);
  const [loading, setLoading] = useState(false);

  const handleEditApply = async (values) => {
    try {
      setLoading(true);

      if (selectedRequest?.id) {
        await Api.put({
          url: '/pool/update',
          data: {
            ...values,
            logo: values.logo?.src,
            poolStatus: values?.poolStatus?.value,
          },
        });
      } else {
        await Api.post({
          url: '/pool/create',
          data: {
            ...values,
            logo: values.logo?.src,
            poolStatus: values?.poolStatus?.value,
          },
        });
      }

      setLoading(false);
      handleRefreshData();
      handleClose();
    } catch (e) {
      console.log('er', e);
      setLoading(false);
    }
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleEditApply)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {selectedRequest?.id ? 'Update pool' : 'Create pool'}
        </h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        {showCropImage && (
          <Field
            name='logo'
            component={DropzonUploaderField}
            label='Image'
            accept='image/*'
            imageOnly
            maxFiles={1}
          />
        )}

        <Field
          name='poolStatus'
          component={SelectField}
          label='Pool status'
          options={POOL_STATUS}
        />

        <Field name='name' component={InputField} label='Pool name' />

        <Field name='symbol' component={InputField} label='Symbol' />

        <Field
          name='totalRaise'
          component={InputField}
          label='Total raise'
          placeholder='$50,000'
        />

        <div className={classes.row}>
          <div className={classes.col}>
            <Field
              name='startDate'
              component={DatePickerField}
              label='Start Date(UTC)'
              viewMode='days'
              timeFormat='HH:mm'
            />
          </div>
          <div className={classes.col}>
            <Field
              name='endDate'
              component={DatePickerField}
              label='End date (UTC)'
              viewMode='days'
              timeFormat='HH:mm'
            />
          </div>
        </div>

        <Field
          name='price'
          component={InputField}
          label='Price'
          placeholder='1ETH = $3000'
        />
        <Field
          name='learnMore'
          component={InputField}
          label='Learn more link'
          placeholder='https or http include'
        />
        <Field
          name='website'
          component={InputField}
          label='Website link'
          placeholder='https or http include'
        />
        <Field
          name='twitter'
          component={InputField}
          label='Twitter link'
          placeholder='https or http include'
        />
        <Field
          name='telegram'
          component={InputField}
          label='Telegram link'
          placeholder='https or http include'
        />
        <Field
          name='currentValue'
          component={InputField}
          label='Current value'
          placeholder='$30,000'
        />
        <Field
          name='progress'
          component={InputField}
          label='Progress'
          placeholder='10%'
        />
        <Field
          name='multipleTimes'
          component={InputField}
          label='Multiple times'
          placeholder='0'
        />
      </div>
      <div className={classes.actions}>
        <Button
          className='btn btnMain btnLarge'
          loading={loading}
          type='submit'
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.logo || !values.logo?.src) {
    errors.logo = 'Please upload logo';
  }

  if (!values.name || !values.name.trim()) {
    errors.name = 'Please enter name';
  }

  if (!values.symbol || !values.symbol.trim()) {
    errors.symbol = 'Please enter name';
  }

  if (!values.totalRaise || !values.totalRaise.trim()) {
    errors.totalRaise = 'Please total raise';
  }

  if (!values.price || !values.price.trim()) {
    errors.price = 'Please enter price';
  }

  return errors;
};

export default reduxForm({
  form: 'PoolForm',
  validate,
  enableReinitialize: true,
})(Form);
