export const GET_SPORES_NEWS = 'admin/sporesNews/getSporesNews';
export const GET_SPORES_NEWS_SUCCESS = 'admin/sporesNews/getSporesNewsSuccess';
export const GET_SPORES_NEWS_FAILURE = 'admin/sporesNews/getSporesNewsFailure';

export const getSporesNews = (params) => ({
  type: GET_SPORES_NEWS,
  params,
});

export const getSporesNewsSuccess = (data) => ({
  type: GET_SPORES_NEWS_SUCCESS,
  data,
});

export const getSporesNewsFailure = () => ({
  type: GET_SPORES_NEWS_FAILURE,
});

export const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SPORES_NEWS:
      return {
        ...state,
        loading: true,
      };
    case GET_SPORES_NEWS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_SPORES_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
