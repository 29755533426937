export const GET_STAKING = 'admin/staking/getStaking';
export const GET_STAKING_SUCCESS = 'admin/staking/getStakingSuccess';
export const GET_STAKING_FAILURE = 'admin/staking/getStakingFailure';

export const getStaking = (params) => ({
  type: GET_STAKING,
  params,
});

export const getStakingSuccess = (data) => ({
  type: GET_STAKING_SUCCESS,
  data,
});

export const getStakingFailure = () => ({
  type: GET_STAKING_FAILURE,
});

export const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_STAKING:
      return {
        ...state,
        loading: true,
      };
    case GET_STAKING_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_STAKING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
