import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/Button';
import * as Api from 'api/api';
import closeIcon from 'images/close.svg';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import DatePickerField from 'components/DatePickerField';
import classes from './Form.module.scss';

const Form = ({
  handleSubmit,
  handleClose,
  handleRefreshData,
  selectedRequest,
  reset,
  show,
}) => {
  useEffect(() => {
    if (show && !selectedRequest?._id) {
      reset();
    }
  }, [show]);
  const [loading, setLoading] = useState(false);

  const handleEditApply = async (values) => {
    try {
      setLoading(true);

      if (selectedRequest?.id) {
        await Api.put({
          url: '/staking/update',
          data: {
            ...values,
            stakingStatus: values.stakingStatus?.value,
          },
        });
      } else {
        await Api.post({
          url: '/staking/create',
          data: {
            ...values,
            stakingStatus: values.stakingStatus?.value,
          },
        });
      }

      setLoading(false);
      handleRefreshData();
      handleClose();
    } catch (e) {
      console.log('er', e);
      setLoading(false);
    }
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleEditApply)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {selectedRequest?.id ? 'Update staking' : 'Create staking'}
        </h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        <Field
          name='stakingStatus'
          component={SelectField}
          label='Pool status'
          options={[
            { label: 'Open', value: 'Open' },
            { label: 'Filled', value: 'Filled' },
          ]}
        />
        <Field name='description' component={InputField} label='Description' />
        <Field name='title' component={InputField} label='Title' />
        <div className={classes.row}>
          <div className={classes.col}>
            <Field
              name='poolSizeLabel'
              component={InputField}
              label='Pool size label'
            />
          </div>
          <div className={classes.col}>
            <Field name='poolSize' component={InputField} label='Pool size' />
          </div>
        </div>

        <Field
          name='startDate'
          component={DatePickerField}
          label='Start date'
          viewMode='days'
        />
        <Field name='startTime' component={InputField} label='Start time' />

        <Field
          name='daysOpen'
          component={InputField}
          label='Days Open For Stake'
          inputType='number'
        />
        <Field
          name='daysLock'
          component={InputField}
          label='Days Of Mandatory Lock'
          inputType='number'
        />
        <Field
          name='rewardReturn'
          component={InputField}
          label='Early Reward Return (APR %)'
          inputType='number'
        />
        <Field
          name='daysMaturity'
          component={InputField}
          label='Days Until Maturity'
          inputType='number'
        />
        <Field
          name='maturityReturn'
          component={InputField}
          label='Maturity Return (APR %)'
          inputType='number'
        />
        <Field name='link' component={InputField} label='Link' />
      </div>
      <div className={classes.actions}>
        <Button
          className='btn btnMain btnLarge'
          loading={loading}
          type='submit'
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.title || !values.title.trim()) {
    errors.title = 'Please enter title';
  }

  if (!values.description || !values.description.trim()) {
    errors.description = 'Please enter description';
  }

  if (!values.link || !values.link.trim()) {
    errors.link = 'Please enter link';
  }

  if (!values.stakingStatus) {
    errors.stakingStatus = 'Please select pool status';
  }

  if (!values.startDate) {
    errors.startDate = 'Please select start date';
  }
  if (!values.startTime || !values.startTime.trim()) {
    errors.startTime = 'Please enter start time';
  }
  if (!values.poolSize || !values.poolSize.trim()) {
    errors.poolSize = 'Please enter pool size';
  }
  if (!values.daysOpen) {
    errors.daysOpen = 'Please enter days open for stake';
  }

  if (!values.daysLock) {
    errors.daysLock = 'Please enter days of mandatory lock';
  }
  if (!values.rewardReturn) {
    errors.rewardReturn = 'Please enter early reward return (APR %)';
  }
  if (!values.daysMaturity) {
    errors.daysMaturity = 'Please enter days until maturity';
  }
  if (!values.maturityReturn) {
    errors.maturityReturn = 'Please enter maturity return (APR %)';
  }

  return errors;
};

export default reduxForm({
  form: 'StakingForm',
  validate,
  enableReinitialize: true,
})(Form);
