export const GET_REQUEST_GALLERY = 'admin/RequestGallery/getRequestGallery';
export const GET_REQUEST_GALLERY_SUCCESS =
  'admin/RequestGallery/getRequestGallerySuccess';
export const GET_REQUEST_GALLERY_FAILURE =
  'admin/RequestGallery/getRequestGalleryFailure';

export const getRequestGallery = (params) => ({
  type: GET_REQUEST_GALLERY,
  params,
});

export const getRequestGallerySuccess = (data) => ({
  type: GET_REQUEST_GALLERY_SUCCESS,
  data,
});

export const getRequestGalleryFailure = () => ({
  type: GET_REQUEST_GALLERY_FAILURE,
});

export const initialState = {
  data: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_REQUEST_GALLERY:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUEST_GALLERY_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_REQUEST_GALLERY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
