import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
// import FloatingLabelInput from 'react-floating-label-input'
import arrowDownIcon from 'images/arrow-down.svg';
// import DateTime from 'react-datetime'
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';
import classes from './SingleSelect.module.scss';

const Icon = ({ selectProps }) => (
  <img
    src={arrowDownIcon}
    className={classNames(
      classes.arrowIcon,
      selectProps.menuIsOpen && classes.arrowIconUp,
    )}
    alt='icon'
  />
);
const components = {
  DropdownIndicator: Icon,
};

export default class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      focusedInput: '',
      startDate: moment(),
      endDate: moment(),
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  moveUpPlaceholder = () => {
    this.setState({
      focus: true,
    });
  };

  moveDownPlaceholder = () => {
    this.setState({
      focus: false,
    });
  };

  handleChange = (selectOption) => {
    const { showCustomDate, changeValue } = this.props;
    if (selectOption.value === 'CUSTOM' && showCustomDate) {
      this.setState({
        // openDatePicker: true
        focusedInput: 'startDate',
        startDate: moment(selectOption.startDate),
        endDate: moment(selectOption.endDate),
      });
    } else {
      changeValue(selectOption);
    }
  };

  handleChangeDate = (date) => {
    this.setState({
      startDate: date.startDate,
      endDate: date.endDate,
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        focusedInput: '',
      });
    }
  };

  handleCancel = () => {
    this.setState({
      focusedInput: '',
    });
  };

  handleSelect = () => {
    const { startDate, endDate } = this.state;
    const { changeValue } = this.props;
    changeValue({
      label: 'CUSTOM',
      value: 'CUSTOM',
      startDate: startDate && startDate.format('YYYY-MM-DD'),
      endDate: endDate
        ? endDate.format('YYYY-MM-DD')
        : startDate.format('YYYY-MM-DD'),
    });
    this.setState({
      focusedInput: '',
    });
  };

  render() {
    const {
      value,
      disabled,
      options,
      customClass,
      loading,
      isClearable,
      placeholder,
      showCustomDate,
      showDateLeft,
    } = this.props;
    let selectValue = value;
    if (showCustomDate && value.value === 'CUSTOM') {
      selectValue = {
        ...value,
        label:
          value.startDate === value.endDate
            ? moment(value.startDate).format('DD.MM.YYYY')
            : `${moment(value.startDate).format('DD.MM.YYYY')} - ${moment(
                value.endDate,
              ).format('DD.MM.YYYY')}`,
      };
    }
    const { focus, startDate, endDate, focusedInput } = this.state;
    return (
      <div
        className={classNames(classes.inputContainer, 'singleSelectContainer')}
      >
        <Select
          isClearable={isClearable}
          onChange={this.handleChange}
          value={selectValue}
          className={classNames(
            classes.input,
            customClass,
            focus && classes.focus,
            'singleSelect',
          )}
          disabled={disabled}
          onFocus={this.moveUpPlaceholder}
          onBlur={this.moveDownPlaceholder}
          options={options}
          isLoading={loading}
          placeholder={placeholder || ''}
          components={components}
          styles={{
            control: () => ({
              border: 0,
              padding: 0,
              margin: '2px 0 0 0px',
            }),
            indicatorsContainer: () => ({
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: 8,
              display: 'flex',
              alignItems: 'center',
            }),
            indicatorSeparator: () => ({}),
            loadingIndicator: () => ({
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '25px',
              width: '45px',
              fontSize: '6px',
              color: '#999999',
            }),
            valueContainer: (styles) => ({
              ...styles,
              padding: styles.padding,
            }),
            singleValue: (styles) => ({
              ...styles,
              paddingRight: isClearable ? 60 : 'unset',
            }),
          }}
        />

        {showCustomDate && focusedInput && (
          <div
            ref={(wrapperRef) => (this.wrapperRef = wrapperRef)}
            className={classNames(
              classes.dateRangerWrapper,
              showDateLeft && classes.dateRangerWrapperLeft,
            )}
          >
            <DayPickerRangeController
              numberOfMonths={2}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={this.handleChangeDate}
              focusedInput={focusedInput}
              onFocusChange={(val) =>
                this.setState({ focusedInput: val || 'startDate' })
              }
              minimumNights={0}
            />
            <div className={classes.actions}>
              <a className='btn btnSmall mr15' onClick={this.handleCancel}>
                Cancel
              </a>
              <a className='btn btnSmall btnBlue' onClick={this.handleSelect}>
                Apply
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
